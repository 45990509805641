export const i18n = ({ index, language }) => {
  const translations = {
    home: {
      en: "Home",
      es: "Inicio",
      fr: "Accueil",
    },
    products: {
      en: "Products",
      es: "Productos",
      fr: "Produits",
    },
    recipes: {
      en: "Recipes",
      es: "Recetas",
      fr: "Recettes",
    },
    find_us: {
      en: "Find Us",
      es: "Encontranos",
      fr: "Où Acheter",
    },
    contact: {
      en: "Contact",
      es: "Contacto",
      fr: "Contact",
    },
    home_1: {
      en: "FLAVORS OF THE MIDDLE EAST",
      es: "SABORES DEL MEDIO ORIENTE",
      fr: "SAVEURS DU MOYEN ORIENT",
    },
    home_2: {
      en: "BROUGHT TO LIFE IN URUGUAY",
      es: "RENACIDOS EN URUGUAY:",
      fr: "RENÉS EN URUGUAY:",
    },
    home_3: {
      en: "WITH A TOUCH OF CREATIVE AUDACITY.",
      es: "LA ESENCIA DE HALAH.",
      fr: "L'ESSENCE DE HALAH.",
    },
    home_4: {
      en: "WE MAKE TAHINI",
      es: "FABRICAMOS TAHINI",
      fr: "NOUS ÉLABORONS DU TAHINI",
    },
    home_5: {
      en: "IN DIFFERENT COLORS AND FLAVOURS.",
      es: "Y PRODUCTOS A BASE DE ÉL.",
      fr: "ET DES PRODUITS À PARTIR DE LUI.",
    },
    home_q1: {
      en: "WHAT?",
      es: "¿QUÉ?",
      fr: "QUOI?",
    },
    home_desc_1: {
      en: "Tahini is a very popular Middle Eastern food consisting in ground sesame seeds which form a creamy paste. It only has one ingredient -sesame- making it naturally vegan as well as sugar and gluten free.",
      es: "El tahini es una pasta de sésamo típica en la cocina del Medio Oriente. Tiene un solo ingrediente -la semilla de sésamo- por lo cual el producto es naturalmente libre de gluten, vegano y saludable.",
      fr: "Le tahini est une pâte de sésame typique dans la cuisine du Moyen Orient. Il n'a qu'un seul ingrédient -les graînes de sésame- et est donc naturellement végétalien, sans gluten et sain.",
    },

    home_q2: {
      en: "HOW?",
      es: "¿CÓMO?",
      fr: "COMMENT?",
    },
    home_desc_2: {
      en: "Mostly known for its role in the preparation of hummus, it's also a great companion for a wide variety of plates. Try using it to season your salad, mixing it into some pasta, or over roasted veggies.",
      es: "Famoso por ser ingrediente fundamental del hummus, es excelente compañero de una gran variedad de platos. Probá mezclarlo en la ensalada, en un plato de pasta, o encima de verduras asadas.",
      fr: "Plutôt connu pour être un ingrédient fondamental de l'hummus, il peut aussi être l'étoile de plusieurs préparations comme vos salades, pâtes, légumes au four et bien plus.",
    },
    home_desc_3: {
      en: "We put special attention into every step of the process so that you could enjoy not only a product of the highest quality but also a work of art.",
      es: "Nuestros métodos de cocina y molienda logran no solamente un producto de especial calidad, sino también belleza artística al poner especial cuidado en los pequeños detalles.",
      fr: "Nous avons une manière unique de produire qui garantit non seulement une haute qualité mais aussi beauté et harmonie que vous pourrez disperser sur vos plats.",
    },
    clients: {
      en: "TRUSTED BY",
      es: "CONFÍAN EN NOSOTROS",
      fr: "ONT CONFIANCE EN NOUS",
    },
    clients_2: {
      en: "AND MORE",
      es: "Y MÁS",
      fr: "ET PLUS",
    },
    contact_q: {
      en: "LET'S TALK",
      es: "HABLEMOS",
      fr: "PARLONS",
    },
    classic: {
      en: "CLASSIC",
      es: "CLÁSICO",
      fr: "CLASSIQUE",
    },
    flavor: {
      en: "FLAVOUR",
      es: "SABORIZADO",
      fr: "PARFUMÉ",
    },
    variety: {
      en: "VARIOUS",
      es: "VARIADO",
      fr: "VARIÉ",
    },
    classic_1: {
      en: "CLASS",
      es: "CLA",
      fr: "CLA",
    },
    classic_2: {
      en: "ICS",
      es: "SI",
      fr: "SSI",
    },
    classic_3: {
      en: "",
      es: "CO",
      fr: "QUE",
    },
    flavor_1: {
      en: "FLAV",
      es: "SABOR",
      fr: "PAR",
    },
    flavor_2: {
      en: "OUR",
      es: "IZADO",
      fr: "FU",
    },
    flavor_3: {
      en: "ED",
      es: "",
      fr: "MÉ",
    },
    sweet: {
      en: "SWEET",
      es: "DULCE",
      fr: "SUCRÉ",
    },
    variety_1: {
      en: "VAR",
      es: "VA",
      fr: "VA",
    },
    variety_2: {
      en: "IOUS",
      es: "RIA",
      fr: "RIÉ",
    },
    variety_3: {
      en: "",
      es: "DO",
      fr: "",
    },
    nutritional_info: {
      en: "Nutrition Facts:",
      es: "Información Nutricional:",
      fr: "Valeur Nutritive:",
    },
    classic_title: {
      en: "Classic Tahini 250g",
      es: "Tahini Clásico 250gr",
      fr: "Tahini Classique 250gr",
    },
    classic_ingredients: {
      en: "Ingredients: 100% toasted and milled sesame seeds.",
      es: "Ingredientes: 100% sésamo tostado y molido.",
      fr: "Ingrédients: 100% graines de sésame rôties et moulues.",
    },
    classic_nutrition: {
      en: "Serving size 15 gr (1 tablespoon). Calories: 99 kcal / 416 (5%VD); Carbohydrate: 1.6 g (1%VD); Protein: 3 g (4%VD); Total Fat: 9.0 g (16%VD); Saturated Fat: 1.4 g (6%VD); Trans Fat: 0 g; Fibre: 1.7 g (7%VD); Sodium: 7.1 mg (0%VD).",
      es: "Porción 15 g (1 cucharada de sopa). Valor energético: 99 kcal / 416 (5%VD); Carbohidratos: 1,6 g (1%VD); Proteínas: 3 g (4%VD); Grasas totales: 9,0 g (16%VD); Grasas saturadas: 1,4 g (6%VD); Grasas trans: 0 g; Fibra alimentaria: 1,7 g (7%VD); Sodio: 7,1 mg (0%VD).",
      fr: "Pour 15 gr (1 c. à soupe). Calories: 99 kcal / 416 (5%VD); Glucides: 1,6 g (1%VD); Protéines: 3 g (4%VD); Lipides: 9,0 g (16%VD); Lipides saturés: 1,4 g (6%VD); Lipides trans: 0 g; Fibre: 1,7 g (7%VD); Sodium: 7,1 mg (0%VD).",
    },
    classic_description: {
      en: "Class in its full flair. The unique taste and texture of our tahini are the result of professional and efficient machines, high-quality sesame seeds, and the love and care put into hand-crafted produce.",
      es: "El clásico, el infaltable. El sabor único de nuestro tahini es el resultado de un equilibrio entre elaboración artesanal, materia prima de calidad, maquinaria profesional y dedicación personal.",
      fr: "Le classique, l'essentiel. Le goût unique de notre tahini est le résultat d'un équilibre entre production artisanale, matières premières de qualité, machines professionnelles et dévouement personnel.",
    },
    silver_title: {
      en: "Silver Tahini 250g",
      es: "Tahini Plateado 250gr",
      fr: "Tahini Argenté 250gr",
    },
    silver_ingredients: {
      en: "Ingredients: 100% toasted and milled black sesame seeds.",
      es: "Ingredientes: 100% sésamo negro tostado y molido.",
      fr: "Ingrédients: 100% graines de sésame noires rôties et moulues.",
    },
    silver_nutrition: {
      en: "Serving size 15 gr (1 tablespoon). Calories: 87 kcal / 371 (4%VD); Carbohydrate: 3.9 g (1%VD); Protein: 2.5 g (3%VD); Total Fat: 7.0 g (13%VD); Saturated Fat: 1 g (5%VD); Trans Fat: 0 g; Fibre: 2.1 g (8%VD); Sodium: 3 mg (0%VD).",
      es: "Porción 15 g (1 cucharada de sopa). Valor energético: 87 kcal / 371 (4%VD); Carbohidratos: 3,9 g (1%VD); Proteínas: 2,5 g (3%VD); Grasas totales: 7,0 g (13%VD); Grasas saturadas: 1 g (5%VD); Grasas trans: 0 g; Fibra alimentaria: 2,1 g (8%VD); Sodio: 3 mg (0%VD).",
      fr: "Pour 15 gr (1 c. à soupe). Calories: 87 kcal / 371 (4%VD); Glucides: 3,9 g (1%VD); Protéines: 2,5 g (3%VD); Lipides: 7,0 g (13%VD); Lipides saturés: 1 g (5%VD); Lipides trans: 0 g; Fibre: 2,1 g (7%VD); Sodium: 3 mg (0%VD).",
    },
    silver_description: {
      en: "Stemming from a lighter-coloured black sesame seed, the softer, subtle taste of this tahini is for you to discover.",
      es: "Elaborado a partir de una semilla negra más clarita, te presentamos un tahini hasta ahora desconocido, con un sabor más suave.",
      fr: "Issu d'une graine de sésame noire plus claire que d'habitude, le charme de ce tahini au goût plus subtile est à vous de découvrir.",
    },
    pesto_title: {
      en: "Pesto Tahini 250g",
      es: "Tahini Pesto 250gr",
      fr: "Tahini Pesto 250gr",
    },
    pesto_ingredients: {
      en: "Ingredients: Toasted and milled sesame seeds, high oleic sunflower oil, dehydrated basil.",
      es: "Ingredientes: Sésamo tostado y molido, aceite de girasol altoleico, albahaca deshidratada.",
      fr: "Ingrédients: Graines de sésame rôties et moulues, huile de tournesol riche en acide oléique, basilic séché.",
    },
    pesto_nutrition: {
      en: "Serving size 15 gr (1 tablespoon). Calories: 99 kcal / 416 (5%VD); Carbohydrate: 1.5 g (1%VD); Protein: 2.6 g (4%VD); Total Fat: 9.2 g (17%VD); Saturated Fat: 1.3 g (6%VD); Trans Fat: 0 g; Fibre: 1.6 g (6%VD); Sodium: 6.7 mg (0%VD).",
      es: "Porción 15 g (1 cucharada de sopa). Valor energético: 99 kcal / 416 (5%VD); Carbohidratos: 1,5 g (1%VD); Proteínas: 2,6 g (4%VD); Grasas totales: 9,2 g (17%VD); Grasas saturadas: 1,3 g (6%VD); Grasas trans: 0 g; Fibra alimentaria: 1,6 g (6%VD); Sodio: 6,7 mg (0%VD).",
      fr: "Pour 15 gr (1 c. à soupe). Calories: 99 kcal / 416 (5%VD); Glucides: 1,5 g (1%VD); Protéines: 2,6 g (4%VD); Lipides: 9,2 g (17%VD); Lipides saturés: 1,3 g (6%VD); Lipides trans: 0 g; Fibre: 1,6 g (6%VD); Sodium: 6,7 mg (0%VD).",
    },
    pesto_description: {
      en: "Tahini in the hands of Halah: where tradition and versatility unite. This rich and creamy pesto version is a combination worth exploring.",
      es: "Tahini en las manos de Halah: donde tradición y versatilidad se unen. Tahini y pesto, sésamo y albahaca: una combinación que vale la pena explorar.",
      fr: "Quand le tahini se trouve dans nos mains, la tradition et la diversité se recontrent. Assurez-vous de goûter cette combinaison avec le pesto.",
    },
    paprika_title: {
      en: "Paprika Tahini 250g",
      es: "Tahini Paprika 250gr",
      fr: "Tahini Paprika 250gr",
    },
    paprika_ingredients: {
      en: "Ingredients: Toasted and milled sesame seeds, high oleic sunflower oil, paprika.",
      es: "Ingredientes: Sésamo tostado y molido, aceite de girasol altoleico, pimentón irradiado.",
      fr: "Ingrédients: Graines de sésame rôties et moulues, huile de tournesol riche en acide oléique, paprika.",
    },
    paprika_nutrition: {
      en: "Serving size 15 gr (1 tablespoon). Calories: 100 kcal / 420 (5%VD); Carbohydrate: 1.6 g (1%VD); Protein: 2.8 g (4%VD); Total Fat: 9.2 g (17%VD); Saturated Fat: 1.3 g (6%VD); Trans Fat: 0 g; Fibre: 1.6 g (6%VD); Sodium: 6.7 mg (0%VD).",
      es: "Porción 15 g (1 cucharada de sopa). Valor energético: 100 kcal / 420 (5%VD); Carbohidratos: 1,6 g (1%VD); Proteínas: 2,8 g (4%VD); Grasas totales: 9,2 g (17%VD); Grasas saturadas: 1,3 g (6%VD); Grasas trans: 0 g; Fibra alimentaria: 1,6 g (6%VD); Sodio: 6,7 mg (0%VD).",
      fr: "Pour 15 gr (1 c. à soupe). Calories: 100 kcal / 420 (5%VD); Glucides: 1,6 g (1%VD); Protéines: 2,8 g (4%VD); Lipides: 9,2 g (17%VD); Lipides saturés: 1,3 g (6%VD); Lipides trans: 0 g; Fibre: 1,6 g (6%VD); Sodium: 6,7 mg (0%VD).",
    },
    paprika_description: {
      en: "Add some color to your plates with this subtly sweet paprika tahini. In the hands of Halah, tradition and versatility unite.",
      es: "Con un color hermoso y un sabor sutilmente dulce, aprovechá el tahini paprika para adornar tus platos favoritos. En las manos de Halah, tradición y versatilidad se unen.",
      fr: "Cette combinaison avec paprika rendra vos plats doués de couleurs et saveurs élégantes. Dans nos mains, la tradition et la diversité se recontrent.",
    },
    sweet_title: {
      en: "Sweet Tahini 235g",
      es: "Tahini Dulce 235gr",
      fr: "Tahini Sucré 235gr",
    },
    sweet_ingredients: {
      en: "Ingredients: Toasted and milled sesame seeds, powdered sugar (sugar, cornstarch), high oleic sunflower oil.",
      es: "Ingredientes: Sésamo tostado y molido, azúcar impalpable (ázucar, almindón de maíz), aceite de girasol altoleico.",
      fr: "Ingrédients: Graines de sésame rôties et moulues, sucre en poudre (sucre, fécule de maïs), huile de tournesol riche en acide oléique.",
    },
    sweet_nutrition: {
      en: "Serving size 20 gr (1 tablespoon). Calories: 114 kcal / 479 (6%VD); Carbohydrate: 9 g (3%VD); Protein: 2.2 g (3%VD); Total Fat: 7.7 g (14%VD); Saturated Fat: 1.1 g (5%VD); Trans Fat: 0 g; Fibre: 1.2 g (5%VD); Sodium: 5.1 mg (0%VD).",
      es: "Porción 20 g (1 cucharada de sopa). Valor energético: 114 kcal / 479 (6%VD); Carbohidratos: 9 g (3%VD); Proteínas: 2,2 g (3%VD); Grasas totales: 7,7 g (14%VD); Grasas saturadas: 1,1 g (5%VD); Grasas trans: 0 g; Fibra alimentaria: 1,2 g (5%VD); Sodio: 5,1 mg (0%VD).",
      fr: "Pour 20 gr (1 c. à soupe). Calories: 114 kcal / 479 (6%VD); Glucides: 9 g (3%VD); Protéines: 2,2 g (3%VD); Lipides: 7,7 g (14%VD); Lipides saturés: 1,1 g (5%VD); Lipides trans: 0 g; Fibre: 1,2 g (5%VD); Sodium: 5,1 mg (0%VD).",
    },
    sweet_description: {
      en: "Tahini as a sweet thing: you have to try it. Thanks to its thicker consistency, it's perfect to have over bread, in pancakes and pastries, to give a few examples.",
      es: "¿Tahini endulzado? Te espera una sorpresa. Es un postre de alta calidad nutricional con un sabor único. Desde untarlo en las tostadas a incorporarlo en pastelería fina, no falla.",
      fr: "Tahini sucré? Une belle surprise. Délicieux et nutritif, il devient un dessert exceptionnel. Vous pouvez l'utiliser comme confiture sur du pain ou dans une patisserie.",
    },
    cacao_title: {
      en: "Chocolate Tahini 235g",
      es: "Tahini Chocolate 235gr",
      fr: "Tahini au Chocolat 235gr",
    },
    cacao_ingredients: {
      en: "Ingredients: Toasted and milled sesame seeds, powdered sugar (sugar, cornstarch), high oleic sunflower oil, dark cacao.",
      es: "Ingredientes: Sésamo tostado y molido, azúcar impalpable (ázucar, almindón de maíz), aceite de girasol altoleico, cacao.",
      fr: "Ingrédients: Graines de sésame rôties et moulues, sucre en poudre (sucre, fécule de maïs), huile de tournesol riche en acide oléique, cacao en poudre.",
    },
    cacao_nutrition: {
      en: "Serving size 20 gr (1 tablespoon). Calories: 109 kcal / 458 (5%VD); Carbohydrate: 8.9 g (3%VD); Protein: 2 g (3%VD); Total Fat: 7.3 g (13%VD); Saturated Fat: 1 g (5%VD); Trans Fat: 0 g; Fibre: 1.1 g (4%VD); Sodium: 4.7 mg (0%VD).",
      es: "Porción 20 g (1 cucharada de sopa). Valor energético: 109 kcal / 458 (5%VD); Carbohidratos: 8,9 g (3%VD); Proteínas: 2 g (3%VD); Grasas totales: 7,3 g (13%VD); Grasas saturadas: 1 g (5%VD); Grasas trans: 0 g; Fibra alimentaria: 1,1 g (4%VD); Sodio: 4,7 mg (0%VD).",
      fr: "Pour 20 gr (1 c. à soupe). Calories: 109 kcal / 458 (5%VD); Glucides: 8,9 g (3%VD); Protéines: 2 g (3%VD); Lipides: 7,3 g (13%VD); Lipides saturés: 1 g (5%VD); Lipides trans: 0 g; Fibre: 1,1 g (4%VD); Sodium: 4,7 mg (0%VD).",
    },
    cacao_description: {
      en: "Tahini and chocolate? You bet. Try it as your new healthy spread. Have it over bread, in pancakes and pastries, or get creative.",
      es: "¿Tahini y chocolate? Claro que sí. Es un postre de alta calidad nutricional con un sabor único. Desde untarlo en las tostadas a incorporarlo en pastelería fina, no falla.",
      fr: "Tahini et chocolat? Oh oui. Délicieux et nutritif, il devient un dessert exceptionnel. Vous pouvez l'utiliser comme confiture sur du pain ou dans une patisserie.",
    },
    sesame_title: {
      en: "Toasted Sesame Seeds 200g",
      es: "Sésamo Tostado 200gr",
      fr: "Sésame Grillé 200gr",
    },
    sesame_ingredients: {
      en: "Ingredients: Toasted sesame seeds.",
      es: "Ingredientes: Sésamo tostado.",
      fr: "Ingrédients: Graines de sésame grillées.",
    },
    sesame_nutrition: {
      en: "Serving size 15 gr (1 tablespoon). Calories: 99 kcal / 416 (5%VD); Carbohydrate: 1.6 g (1%VD); Protein: 3 g (4%VD); Total Fat: 9.0 g (16%VD); Saturated Fat: 1.4 g (6%VD); Trans Fat: 0 g; Fibre: 1.7 g (7%VD); Sodium: 7.1 mg (0%VD).",
      es: "Porción 15 g (1 cucharada de sopa). Valor energético: 99 kcal / 416 (5%VD); Carbohidratos: 1,6 g (1%VD); Proteínas: 3 g (4%VD); Grasas totales: 9,0 g (16%VD); Grasas saturadas: 1,4 g (6%VD); Grasas trans: 0 g; Fibra alimentaria: 1,7 g (7%VD); Sodio: 7,1 mg (0%VD).",
      fr: "Pour 15 gr (1 c. à soupe). Calories: 99 kcal / 416 (5%VD); Glucides: 1,6 g (1%VD); Protéines: 3 g (4%VD); Lipides: 9,0 g (16%VD); Lipides saturés: 1,4 g (6%VD); Lipides trans: 0 g; Fibre: 1,7 g (7%VD); Sodium: 7,1 mg (0%VD).",
    },
    sesame_description: {
      en: "We got the sesame seeds and we roasted them like we do best. We're leaving the rest up to you.",
      es: "Nosotros tenemos las semillas y sabemos tostarlas. En este caso dejamos lo que sigue en sus manos.",
      fr: "Nous avons les graines de sésame et nous savons les griller. Nous avons cette fois-ci laissé le reste à vous.",
    },
    big_title: {
      en: "Classic Tahini 475g",
      es: "Tahini Clásico 475gr",
      fr: "Tahini Classique 475gr",
    },
    big_ingredients: {
      en: "Ingredients: 100% toasted and milled sesame seeds.",
      es: "Ingredientes: 100% sésamo tostado y molido.",
      fr: "Ingrédients: 100% graines de sésame rôties et moulues.",
    },
    big_nutrition: {
      en: "Serving size 15 gr (1 tablespoon). Calories: 99 kcal / 416 (5%VD); Carbohydrate: 1.6 g (1%VD); Protein: 3 g (4%VD); Total Fat: 9.0 g (16%VD); Saturated Fat: 1.4 g (6%VD); Trans Fat: 0 g; Fibre: 1.7 g (7%VD); Sodium: 7.1 mg (0%VD).",
      es: "Porción 15 g (1 cucharada de sopa). Valor energético: 99 kcal / 416 (5%VD); Carbohidratos: 1,6 g (1%VD); Proteínas: 3 g (4%VD); Grasas totales: 9,0 g (16%VD); Grasas saturadas: 1,4 g (6%VD); Grasas trans: 0 g; Fibra alimentaria: 1,7 g (7%VD); Sodio: 7,1 mg (0%VD).",
      fr: "Pour 15 gr (1 c. à soupe). Calories: 99 kcal / 416 (5%VD); Glucides: 1,6 g (1%VD); Protéines: 3 g (4%VD); Lipides: 9,0 g (16%VD); Lipides saturés: 1,4 g (6%VD); Lipides trans: 0 g; Fibre: 1,7 g (7%VD); Sodium: 7,1 mg (0%VD).",
    },
    big_description: {
      en: "Class in its full flair. If you run out too quickly, consider this larger option.",
      es: "El clásico, el infaltable. Si se te va muy rápido el chico, optá por este envase.",
      fr: "Le classique, l'essentiel. Si le petit s'en va trop rapidement, essayer ce pot plus large.",
    },
    start: {
      en: "THE TASTE OF REAL",
      es: "SABOR A REAL",
      fr: "GOUTEZ LE RÉEL",
    },
    start_1: {
      en: "THE TASTE",
      es: "SABOR",
      fr: "GOÛTEZ",
    },
    start_2: {
      en: "OF REAL",
      es: "A REAL",
      fr: "LE RÉEL",
    },
    history: {
      en: "HISTORY",
      es: "HISTORIA",
      fr: "HISTOIRE",
    },
    history_1: {
      en: "Coral, in charge of sales — also, writing this— really doesn't like marketing, you should know. Having said that, Myriam and Alejandro, her parents, are the other two building blocks of the family company.",
      es: "A Coral, vendedora y cara visible de Halah  — además, quien se encuentra aquí escribiendo — no le gusta para nada el marketing. Dicho esto, Myriam y Alejandro, sus padres, son las otras dos piezas fundadoras de esta empresa familiar.",
      fr: "Coral, responsable des ventes — en plus, celle qui écrit ici — n'aime pas du tout le marketing. Cela étant dit, Myriam et Alejandro, ses parents, sont les deux autres piliers de l'entreprise familiale.",
    },
    history_2: {
      en: "Myriam is a painter, designer, dancer and artist all-around. Alejandro is a musician, businessman, and sometimes a bit of a nutcase. Coral was or tried to be a professional tennis player. Although she wasn't able to get the results she wanted, she trained with a level of determination that she still carries everywhere.",
      es: "Myriam es artista plástica, artista firme, diseñadora y soñadora.  Alejandro es músico, empresario, y a veces un poco atípico. Coral fue o quizo ser tenista profesional. Hizo lo que pudo y sus resultados no estuvieron a la altura, pero creanme que de esos niveles de exigencia uno no se olvida.",
      fr: "Myriam est artiste dans tout ce qu'elle fait. Alejandro est musicien, homme d'affaires et parfois un peu fou. Coral était ou a voulu être joueuse de tennis professionnelle. Bien qu'elle n'ait pas pu obtenir les résultats qu'elle voulait, elle s'est entraînée avec un niveau de détermination qu'elle porte toujours partout.",
    },
    history_3: {
      en: "So where does the idea of making tahini come from? Well, for starters, years of living in Canada, where punctuality, snow, and culinary diversity are king. Tahini is easy to find there, and not so easy to find in Uruguay, which is where they now live and where they started making it. Let's not forget honest appreciation for healthy, hearty foods.",
      es: "¿Y qué tiene que ver el tahini? Bueno, años de vida en Canadá, por un lado, donde reinan la puntualidad, la nieve y la diversidad cultural. Por otro, gusto personal por el tahini, la comida saludable y la filosofía, o eso de encontrarle preguntas a las respuestas.",
      fr: "Mais d'où vient l'idée de faire du tahini? Et bien, pour commencer, leurs années vécues  au Canada, où la ponctualité, la neige et la diversité culinaire sont reines. Le tahini est facile à trouver là-bas, et pas si facile en Uruguay, où ils vivent maintenant et où ils ont commencé à le fabriquer. N'oublions pas leur honnête appréciation de l'alimentation saine.",
    },
    history_4: {
      en: "Anyway, in 2021, amidst a tennis career and a Planet Earth in crisis, Halah was born.",
      es: "En síntesis, ya con un largo tiempo habitando el hermoso Uruguay, en el 2021, con una carrera tenística y un planeta Tierra en crisis, nace Halah.",
      fr: "En tout cas, en 2021, au milieu d'une carrière de tennis et d'une planète Terre en crise, Halah est née.",
    },
    history_5: {
      en: "Grit, patience, balance, creativity… these are all staples around here. Every day, we strive to provide a high quality product and service. And just to let you know, when you carry one of our tahinis in your hand, you look gorgeous. ;)",
      es: "Garra, paciencia, equilibrio, creatividad… estas cosas son conocidas por acá. Nos esforzamos todos los días por alimentar con productos y servicio de calidad. Y de paso, les avisamos que con un tahini en sus manos, se ven muy guapos. ;)",
      fr: "Courage, patience, équilibre, créativité… ce sont toutes des choses bien connues dans ce coin. Chaque jour, nous faisons l'effort pour pouvoir fournir un produit et un service de la plus haute qualité. Et juste comme ça en passant, lorsque vous portez un de nos tahinis dans votre main, vous êtes splendides. ;)",
    },
    see_recs: {
      en: "See Recipes",
      es: "Recetas",
      fr: "Voir quelques recettes",
    },
    ingredients: {
      en: "INGREDIENTS",
      es: "INGREDIENTES",
      fr: "INGRÉDIENTS",
    },
    base: {
      en: "BASE",
      es: "BASE",
      fr: "BASE",
    },
    sauce: {
      en: "SAUCE",
      es: "SALSA",
      fr: "SAUCE",
    },
    dressing: {
      en: "DRESSING",
      es: "ADEREZO",
      fr: "VINAIGRETTE",
    },
    extra: {
      en: "EXTRAS",
      es: "EXTRAS",
      fr: "EXTRAS",
    },
    essential: {
      en: "ESSENTIALS",
      es: "ESENCIALES",
      fr: "ESSENTIELS",
    },
    optional: {
      en: "OPTIONALS",
      es: "OPCIONALES",
      fr: "OPTIONNELS",
    },
    note: {
      en: "NOTE",
      es: "NOTA",
      fr: "NOTE",
    },
    masa: {
      en: "DOUGH",
      es: "MASA",
      fr: "PÂTE",
    },
    toppings: {
      en: "TOPPINGS",
      es: "EXTRAS",
      fr: "GARNITURE",
    },
    prep: {
      en: "PREPARATION",
      es: "PREPARACIÓN",
      fr: "PRÉPARATION",
    },
    portions: {
      en: "Serves two:",
      es: "Rinde 2 porciones:",
      fr: "Pour deux personnes:",
    },
    portions_1: {
      en: "Per serving:",
      es: "Por porción:",
      fr: "Par portion:",
    },
    portions_2: {
      en: "For 15 cookies:",
      es: "Para 15 galletitas:",
      fr: "Pour 15 biscuits:",
    },
    noodles: {
      en: "MIDDLE EAST & ASIA FUSION NOODLES",
      es: "FIDEOS FUSIÓN ASIA & MEDIO ORIENTE",
      fr: "NOUILLES FUSION ASIE & MOYEN ORIENT",
    },
    n_base_1: {
      en: "• Rice noodles (250g)",
      es: "• Fideos de arroz (250gr)",
      fr: "• Nouilles au riz (250gr)",
    },
    n_base_2: {
      en: "• Broccoli (1 head)",
      es: "• Brócoli (1 ramo)",
      fr: "• Brocoli (1 tête)",
    },
    n_sauce_1: {
      en: "• Tahini (1/3 cup)",
      es: "• Tahini (1/3 taza)",
      fr: "• Tahini (1/3 tasse)",
    },
    n_sauce_2: {
      en: "• Soy sauce (2 tablespoons)",
      es: "• Salsa de Soja (2 cucharas soperas)",
      fr: "• Sauce de soya (2 cuillères à soupe)",
    },
    n_sauce_3: {
      en: "• Honey (2 tablespoons)",
      es: "• Miel (2 cucharas soperas)",
      fr: "• Miel (2 cuillères à soupe)",
    },
    n_sauce_4: {
      en: "• Chili oil (1/2 teaspoon)",
      es: "• Aceite de chili (1/2 cuchara de té)",
      fr: "• Huile de chili (1/2 cuillère à thé)",
    },
    n_sauce_5: {
      en: "• Fresh grated ginger (1 teaspoon)",
      es: "• Jengibre fresco rallado (1 cuchara de té)",
      fr: "• Gingembre frais (1 cuillère à thé)",
    },
    n_sauce_6: {
      en: "• Water (for consistency)",
      es: "• Agua (para consistencia)",
      fr: "• Eau (pour la consistance)",
    },
    n_extra_1: {
      en: "• Chili flakes (1 teaspoon)",
      es: "• Ají molido (1 cuchara de té)",
      fr: "• Flocons de chili (1 cuillère à thé)",
    },
    n_extra_2: {
      en: "• Toasted sesame seeds (1 teaspoon)",
      es: "• Semillas de sésamo tostado (1 cuchara de té)",
      fr: "• Graines de sésame grillées (1 cuillère à thé)",
    },
    n_step_1: {
      en: "1. In a frying pan, cook the broccoli with olive or sesame oil.",
      es: "1. En una sartén, dorar el brócoli con aceite de oliva o de sésamo.",
      fr: "1. Dans une poêle, faites dorer le brocoli avec de l'huile d'olive ou de sésame.",
    },
    n_step_2: {
      en: "2. Meanwhile, cook the noodles in boiling water according to the instructions from your brand of choice.",
      es: "2. Mientras tanto, cocinar los fideos en agua hirviendo según instrucciones del paquete elegido.",
      fr: "2. Pendant ce temps, faites cuire les nouilles dans l'eau bouillante selon les instructions figurant sur l'emballage de votre choix.",
    },
    n_step_3: {
      en: "3. Mix the ingredients for the sauce.",
      es: "3. Mezclar los ingredientes de la salsa.",
      fr: "3. Mélangez les ingrédients de la sauce.",
    },
    n_step_4: {
      en: "4. When the noodles are ready, add them to the pan with the broccoli and mix everything with the sauce.",
      es: "4. Cuando están prontos los fideos, agregarlos a la sartén con el brócoli y mezclar todo con la salsa.",
      fr: "4. Lorsque les nouilles sont prêtes, ajoutez-les dans la poêle avec le brocoli et mélangez le tout avec la sauce.",
    },
    n_step_5: {
      en: "5. Serve and decorate with chili flakes and sesame seeds to your liking.",
      es: "5. Servir y decorar con ají y sésamo a gusto.",
      fr: "5. Servir et décorer avec le chili et les graines de sésame à votre goût.",
    },
    toast: {
      en: "NEWTON'S TOAST",
      es: "LAS TOSTADAS DE NEWTON",
      fr: "LES TARTINADES DE NEWTON",
    },
    t_base_1: {
      en: "• Rye bread (y)",
      es: "• Pan de campo (y)",
      fr: "• Pain de campagne (y)",
    },
    t_base_2: {
      en: "• Apple (x)",
      es: "• Manzana (x)",
      fr: "• Pomme (x)",
    },
    t_base_3: {
      en: "• Tahini (x + y)",
      es: "• Tahini (x + y)",
      fr: "• Tahini (x + y)",
    },
    t_extra_1: {
      en: "• Cinnamon (x/4)",
      es: "• Canela (x/4)",
      fr: "• Cannelle (x/4)",
    },
    t_extra_2: {
      en: "• Pistachios (x - 7)",
      es: "• Pistachos (x - 7)",
      fr: "• Pistaches (x - 7)",
    },
    t_extra_3: {
      en: "• Honey",
      es: "• Miel",
      fr: "• Miel",
    },
    t_comment: {
      en: "We began to calculate the laws of gravity and if x is equal to the amount of apple, that variable was left unresolved. :)",
      es: "Nos pusimos a calcular las leyes de la gravedad, y si x es igual a la cantidad de manzana, esa variable quedó sin resolver. :)",
      fr: "Nous avons commencé à calculer les lois de la gravité et si x est égal à la quantité de pomme, cette variable est restée inconnue. :)",
    },
    t_step_1: {
      en: "1. Cut the apple into thin slices and cook in a pan with a little honey until golden.",
      es: "1. Cortar la manzana en rodajas finas y dorar en una sartén con un poquito de miel.",
      fr: "1. Coupez la pomme en fines tranches et faites-la revenir dans une poêle avec un peu de miel.",
    },
    t_step_2: {
      en: "2. Meanwhile, gently toast your sliced bread. You could chose whatever type you like best.",
      es: "2. Mientras tanto, tostar ligeramente el pan. Podés elegir el pan que más te guste.",
      fr: "2. Pendant ce temps, griller légèrement le pain. Vous pouvez choisir celui que vous préférez.",
    },
    t_step_3: {
      en: "3. Spread the toast with a generous amount of tahini.",
      es: "3. Untar el pan con mucho tahini.",
      fr: "3. Tartinez le pain avec beaucoup de tahini.",
    },
    t_step_4: {
      en: "4. Place the caramelized apple above, then top with ground cinnamon and pistachios. Feel free to switch apples for bananas or give it your own special touch.",
      es: "4. Colocar la manzana caramelizada encima y agregarle canela molida y pistachos. Sentite libre de cambiar manzana por banana o darle tu toque especial.",
      fr: "4. Placez la pomme caramélisée dessus, puis garnissez de cannelle et de pistaches. N'hésitez pas à remplacer pomme par banane ou à personnaliser comme vous souhaitez.",
    },
    salad: {
      en: "TROPICAL GREEK SALAD",
      es: "ENSALADA GRIEGA TROPICAL",
      fr: "SALADE GRECQUE TROPICALE",
    },
    s_base_1: {
      en: "• Cucumber (1 medium)",
      es: "• Pepino (1 mediano)",
      fr: "• Concombre (1 moyen)",
    },
    s_base_2: {
      en: "• Orange (1 large)",
      es: "• Naranja (1 grande)",
      fr: "• Orange (1 grande)",
    },
    s_base_3: {
      en: "• Bell pepper (1/4)",
      es: "• Morrón (1/4)",
      fr: "• Poivron (1/4)",
    },
    s_base_4: {
      en: "• Feta cheese (40g)",
      es: "• Queso feta (40gr)",
      fr: "• Fromage feta (40gr)",
    },
    s_base_5: {
      en: "• Blueberries (1/3 cup)",
      es: "• Arándanos (1 puñado)",
      fr: "• Bleuets (1/3 tasse)",
    },
    s_sauce_1: {
      en: "• Tahini (2 tablespoons)",
      es: "• Tahini (2 cucharas soperas)",
      fr: "• Tahini (2 cuillères à soupe)",
    },
    s_sauce_2: {
      en: "• Olive oil (1/2 tablespoon)",
      es: "• Aceite de oliva (1/2 cuchara sopera)",
      fr: "• Huile d'olive (1/2 cuillère à soupe)",
    },
    s_extra_1: {
      en: "• Pumpkin seeds (1 tablespoon)",
      es: "• Semillas de zapallo (1 cuchara sopera)",
      fr: "• Graines de citrouille (1 cuillère à soupe)",
    },
    s_extra_2: {
      en: "• Sea salt",
      es: "• Sal marina",
      fr: "• Sel de mer",
    },
    s_extra_3: {
      en: "• Oregano",
      es: "• Oregano",
      fr: "• Origan",
    },
    s_step_1: {
      en: "1. Cut the base ingredients into cubes or the shape of your preference and place them together in a bowl.",
      es: "1. Cortar los ingredientes de la base en cubos o la forma de su preferencia y juntarlos en un bowl.",
      fr: "1. Coupez les ingrédients de base en cubes ou selon la forme de votre choix et mélangez l'ensemble dans un bol.",
    },
    s_step_2: {
      en: "2. Mix the tahini with olive oil for the dressing.",
      es: "2. Mezclar el tahini con aceite de oliva para el aderezo.",
      fr: "2. Mélangez le tahini avec l'huile d'olive pour faire la vinaigrette.",
    },
    s_step_3: {
      en: "3. Add the sauce to the bowl and mix well again.",
      es: "3. Agregar la salsa al bowl y volver a mezclar bien.",
      fr: "3. Ajoutez la sauce dans le bol et mélangez bien à nouveau.",
    },
    s_step_4: {
      en: "4. Serve and sprinkle pumpking seeds and oregano. Add salt and pepper to your liking.",
      es: "4. Servir y espolvorear semillas de calabaza y orégano. Agregar sal y pimienta a gusto.",
      fr: "4. Servir et saupoudrer de graines de citrouille et d'origan. Ajoutez du sel et du poivre à votre goût.",
    },
    cookies: {
      en: "TAHINI, ALMOND, COOKIE",
      es: "ALMENDRA, TAHINI, GALLETA",
      fr: "AMANDE, TAHINI, BISCUIT",
    },
    c_base_1: {
      en: "• Almond flour (2 cups)",
      es: "• Harina de almendras (2 tasas)",
      fr: "• Farine d'amandes (2 tasses)",
    },
    c_base_2: {
      en: "• Tahini (2/3 cup)",
      es: "• Tahini (2/3 tasa)",
      fr: "• Tahini (2/3 tasse)",
    },
    c_base_3: {
      en: "• Honey (1/2 cup)",
      es: "• Miel (1/2 tasa)",
      fr: "• Miel (1/2 tasse)",
    },
    c_base_4: {
      en: "• Baking powder (1/2 teaspoon)",
      es: "• Polvo de hornear (1/2 cuchara de té)",
      fr: "• Levure (1/2 cuillère à thé)",
    },
    c_extra_1: {
      en: "• Chocolate chips (2 tablespoons)",
      es: "• Pepitas de chocolate (2 cucharas soperas)",
      fr: "• Pépites de chocolat (2 cuillères à soupe)",
    },
    c_step_1: {
      en: "1. Preheat the oven to 180°C.",
      es: "1. Precalentar el horno a 180°C.",
      fr: "1. Préchauffer le four à 180°C.",
    },
    c_step_2: {
      en: "2. Mix all the ingredients together to obtain a homogeneous dough.",
      es: "2. Mezclar todos los ingredientes hasta obtener una masa homogénea.",
      fr: "2. Mélangez tous les ingrédients jusqu'à obtenir une pâte homogène.",
    },
    c_step_3: {
      en: "3. Line a baking sheet with parchment paper or brush it with olive oil.",
      es: "3. Con un pincel, aceitar una fuente.",
      fr: "3. Ètaler un peu d'huile d'olive sur un plateau.",
    },
    c_step_4: {
      en: "4. Roll the dough into little balls, place them on the baking sheet and press down to flatten slightly.",
      es: "4. Con la masa, formar bolitas y aplastarlas sobre la fuente.",
      fr: "4. Avec la pâte, formez des petites boules et aplatissez-les sur le plateau.",
    },
    c_step_5: {
      en: "5. Cook for 5-10 minutes or until the edges are golden brown.",
      es: "5. Cocinar 5-10 minutos o hasta que los bordes estén dorados.",
      fr: "5. Cuire 5 à 10 minutes ou jusqu'à ce que les bords soient dorés.",
    },
    n_noodles: {
      en: "Fusion Noodles",
      es: "Fideos Fusión",
      fr: "Nouilles Fusion",
    },
    n_toast: {
      en: "Newton's Toast",
      es: "Las Tostadas de Newton",
      fr: "Les Tartinades de Newton",
    },
    n_salad: {
      en: "Tropical Greek Salad",
      es: "Ensalada Griega Tropical",
      fr: "Salade Grecque Tropicale",
    },
    n_cookies: {
      en: "Tahini, Almond, Cookie",
      es: "Almendra, Tahini, Galleta",
      fr: "Amande, Tahini, Biscuit",
    },
  };

  const translation = translations[index][language];

  return translation;
};

export const Language = () => {};
