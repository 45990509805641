import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import logo from "./../pics/logo.png";
import lsqa from "./../pics/lsqa.png";
import foot from "./../pics/Halah0225_web.jpg";
// import all from "./../pics/120_crop.jpg";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { i18n } from "../i18n";
import { useUserSettings } from "../user";

export const Footer = () => {
  const [settings] = useUserSettings();
  const { language } = settings;

  const Mailto = ({ email, subject = "", body = "", children }) => {
    let params = subject || body ? "?" : "";
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  const MobileView = () => {
    return (
      <div className="footer_mobile">
        <img src={foot} class="footer_img_m" alt="" />
        <div className="footer_title_m">
          <img src={logo} alt="" className="footer_logo_mobile"></img>
          <img src={lsqa} alt="" className="footer_lsqa_mobile"></img>
          <div className="footer_row_mobile">
            <button className="footer_email_m">
              <Mailto email="coralscherb@gmail.com" subject="Halah" body="">
                <EmailOutlinedIcon
                  className="footer_email_m"
                  style={{ height: 30, width: 30 }}
                />
              </Mailto>
            </button>
            <button className="footer_email_m">
              <a href="https://instagram.com/halah.uy">
                <InstagramIcon
                  className="footer_email_m"
                  style={{ height: 30, width: 30 }}
                  // style={{ color: "#68f0d7", height: 30, width: 30 }}
                />
              </a>
            </button>
            <button className="footer_email_m">
              <a href="https://www.facebook.com/p/Halah-100083667480107/?locale=fy_NL">
                <FacebookSharpIcon
                  className="footer_email_m"
                  style={{ height: 30, width: 30 }}
                />
              </a>
            </button>
          </div>
        </div>
        <div className="footer_links_mobile">
          <Link className="footer_opt_m" to={"/"}>
            {i18n({ index: "home", language })}
          </Link>
          <Link className="footer_opt_m" to={"/products"}>
            {i18n({ index: "products", language })}
          </Link>
          <Link className="footer_opt_m" to={"/where"}>
            {i18n({ index: "find_us", language })}
          </Link>
          <Link className="footer_opt_m" to={"/contact"}>
            {i18n({ index: "contact", language })}
          </Link>
        </div>

        <div className="copyright_mobile">
          <text>
            © 2024 Halah • Punta Ballena, Uruguay • All rights reserved.
          </text>
          <br />
          <text>Designed & built by Coral.</text>
        </div>
      </div>
    );
  };

  const DesktopView = () => {
    return (
      <div className="footer">
        {/* <AsyncImage
          style={styled}
          src={foot}
          // Transition={Fade}
        /> */}
        <img src={foot} class="footer_img" alt="" />
        <div className="footer_title">
          <img src={logo} alt="" className="footer_logo"></img>
          <img src={lsqa} alt="" className="footer_lsqa"></img>
        </div>
        <div className="copyright">
          <div className="footer_links">
            <Link className="footer_opt" to={"/"}>
              {i18n({ index: "home", language })}
            </Link>
            <Link className="footer_opt" to={"/products"}>
              {i18n({ index: "products", language })}
            </Link>
            <Link className="footer_opt" to={"/where"}>
              {i18n({ index: "find_us", language })}
            </Link>
            <Link className="footer_opt" to={"/contact"}>
              {i18n({ index: "contact", language })}
            </Link>
          </div>
          <text className="designed_by">Designed & built by Coral.</text>
          <text className="designed_by">
            © 2024 Halah • Punta Ballena, Uruguay • All rights reserved.
          </text>
        </div>
        <div className="footer_title">
          <button className="footer_email">
            <Mailto email="coralscherb@gmail.com" subject="Halah" body="">
              <EmailOutlinedIcon
                className="footer_email"
                style={{ height: 35, width: 35 }}
              />
            </Mailto>
          </button>
          <button className="footer_email">
            <a href="https://instagram.com/halah.uy">
              <InstagramIcon
                className="footer_email"
                style={{ height: 35, width: 35 }}
              />
            </a>
          </button>
          <button className="footer_email">
            <a href="https://www.facebook.com/p/Halah-100083667480107/?locale=fy_NL">
              <FacebookSharpIcon
                className="footer_email"
                style={{ height: 35, width: 35 }}
              />
            </a>
          </button>
        </div>
      </div>
    );
  };

  return <div>{isMobile ? <MobileView /> : <DesktopView />}</div>;
};
